import React from 'react';
import { graphql } from 'gatsby';
import ReactMarkdown from 'react-markdown';
import PropTypes from 'prop-types';
import { formatDistanceToNow } from 'date-fns';

import { es } from 'date-fns/locale';

// Material Design
import { Grid, Row, Cell } from '@material/react-layout-grid';
import { Headline2, Headline6, Body1 } from '@material/react-typography';

// Special Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faTwitter } from '@fortawesome/free-brands-svg-icons';

// Layout y componentes
import Layout from '../components/layout';
import Newsletter from '../components/shared/newsletter';
import SEO from '../components/seo';

const parseDate = (date) => formatDistanceToNow(new Date(date), { locale: es });

const ArticleTemplate = ({ data: { contentfulArticulo: article } }) => (
  <Layout>
    <SEO description={article.contenido} title={article.title} />
    <Grid className="mdc-article-template">
      <Row>
        <Cell
          columns={3}
          desktopColumns={3}
          phoneColumns={4}
          tabletColumns={8}
        />
        <Cell columns={6} desktopColumns={6} phoneColumns={4} tabletColumns={8}>
          <Headline2>{article.title}</Headline2>
          <Headline6>
            Por&nbsp;
            <strong>
              {article.authors
                .map(({ fullName }) => fullName)
                .reduce(
                  (initial, currentValue, index) =>
                    `${initial}${
                      index === article.authors.length - 1 ? ' y ' : ', '
                    } ${currentValue}`,
                )}
            </strong>
          </Headline6>
        </Cell>
      </Row>
      <Row>
        <Cell columns={3} phoneColumns={4} tabletColumns={8} />
        <Cell columns={6} phoneColumns={4} tabletColumns={8}>
          <Row>
            <Cell columns={6} phoneColumns={2} tabletColumns={4}>
              <Body1>{parseDate(article.createdAt)}</Body1>
            </Cell>
            <Cell
              columns={6}
              phoneColumns={2}
              tabletColumns={4}
              className="mdc-align__right"
            >
              <FontAwesomeIcon icon={faFacebook} />
              <FontAwesomeIcon icon={faTwitter} />
              <FontAwesomeIcon icon={faComment} />
            </Cell>
          </Row>
        </Cell>
      </Row>
    </Grid>

    <Grid>
      <Row>
        <Cell columns={3} phoneColumns={4} tabletColumns={8} />
        <Cell columns={6} phoneColumns={4} tabletColumns={8}>
          <ReactMarkdown
            className="markdown-article-content"
            source={article.contenido.contenido}
          />
        </Cell>

        <Cell columns={3} phoneColumns={4} tabletColumns={8} />
        <Cell columns={12} phoneColumns={4} tabletColumns={8}>
          <Newsletter />
        </Cell>
      </Row>
    </Grid>
  </Layout>
);

ArticleTemplate.propTypes = {
  data: PropTypes.shape({
    contentfulArticulo: PropTypes.object,
  }),
};

export const articleTemplateQuery = graphql`
  query articleTemplateQuery($slug: String!) {
    contentfulArticulo(slug: { eq: $slug }) {
      createdAt
      title
      authors {
        fullName
      }
      contenido {
        contenido
      }
    }
  }
`;

export default ArticleTemplate;
